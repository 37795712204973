$white: #FFF;
$black: #000;
$background: #FFF;
$primary: #575553;
$fonts: #333333;

/**** Primary Colors ****/
$primary-050: #FAF8F5;
$primary-100: #F2F0ED;
$primary-200: #E8E6E3;
$primary-300: #D7D5D2;
$primary-400: #B3B0AE;
$primary-500: #92908E;
$primary-600: #6A6866;
$primary-700: $primary; //default 
$primary-800: #393735;
$primary-900: #191715;

/**** Secondary Colors ****/
$secondary-050: #FCFBF9;
$secondary-100: #F6F1EB;
$secondary-200: #E9DED1;
$secondary-300: #DDCBB6;
$secondary-400: #D0B89B;
$secondary-500: #C4A581;
$secondary-600: #B79266; //default
$secondary-700: #A77F4F;
$secondary-800: #8C6A42;
$secondary-900: #725636;

/**** Tertiary Colors ****/
$tertiary-050: #FFDFCE;
$tertiary-100: #FBBDB2;
$tertiary-200: #DC988F;
$tertiary-300: #BC746A; //default
$tertiary-400: #A5584F;
$tertiary-500: #8D3D35;
$tertiary-600: #81342F;
$tertiary-700: #702826;
$tertiary-800: #601B20;
$tertiary-900: #4F0C17;

/**** Warning Colors ****/
$danger: #F44336;
$info: #5689A6;
$success: #6DA656;
$warning: #EA961E;


/**** Support Colors ****/
$apoio-01-light: #ffcdd2;
$apoio-02-light: #f8bbd0;
$apoio-03-light: #e1bee7;
$apoio-04-light: #d1c4e9;
$apoio-05-light: #c5cae9;
$apoio-06-light: #bbdefb;
$apoio-07-light: #b3e5fc;
$apoio-08-light: #b2ebf2;
$apoio-09-light: #b2dfdb;
$apoio-10-light: #c8e6c9;
$apoio-11-light: #dcedc8;
$apoio-12-light: #f0f4c3;
$apoio-13-light: #fff9c4;
$apoio-14-light: #ffecb3;
$apoio-15-light: #ffe0b2;
$apoio-16-light: #ffccbc;
$apoio-17-light: #d7ccc8;
$apoio-18-light: #cfd8dc;

$apoio-01-dark: #b71c1c;
$apoio-02-dark: #880e4f;
$apoio-03-dark: #4a148c;
$apoio-04-dark: #311b92;
$apoio-05-dark: #1a237e;
$apoio-06-dark: #0d47a1;
$apoio-07-dark: #01579b;
$apoio-08-dark: #006064;
$apoio-09-dark: #004d40;
$apoio-10-dark: #1b5e20;
$apoio-11-dark: #33691e;
$apoio-12-dark: #827717;
$apoio-13-dark: #fbc02d;
$apoio-14-dark: #ff6f00;
$apoio-15-dark: #e65100;
$apoio-16-dark: #bf360c;
$apoio-17-dark: #3e2723;
$apoio-18-dark: #263238;

/**** Gray Colors ****/
$gray-100: #e9e9e9;
$gray-200: #d9d9d9;
$gray-300: #c4c4c4;
$gray-400: #9d9d9d;
$gray-500: #7b7b7b;
$gray-600: #555555;
$gray-700: #434343;
$gray-800: #262626;
$gray-900: #000000;