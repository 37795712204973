@import "./colors";
@import "./grid";
@import "./spaces";
@import "./typography";

$borderRadiusPadrao: 16px;

* {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    background-color: $background;
    color: $fonts;
    height: 100%;
}

#main {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
}

button {
    border: 0;
    margin-left: 10px;
    color: $primary;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    &:active,
    &:focus {
        outline: 0;
    }

    &:disabled {
        opacity: 0.4;
    }
}

a {
    cursor: pointer;
}