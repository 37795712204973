.box-select {
    width: 200px;
}

.container-analise-prioritaria {
    display: flex;
    justify-content: space-between;
}

.content-analise-prioritaria {
    width: 50%;
    padding: 0 16px;
    margin-bottom: 20px;
}

.box-radio-select {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .mp-radio:first-child {
        margin-right: 18%;
    }

    .mp-radio:nth-child(2) {
        margin-right: 3%;
    }
}