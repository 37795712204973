.button-search {
    margin-top: 20px;
}

.label-tooltip {
    align-items: center;
}

.table-1 {
    margin-top: 10px;

    table tbody tr td {
        padding: 16px 16px !important;
    }

    td {
        vertical-align: top;
        padding: 10px;
    }

    tr td:first-child {
        width: 7%;
    }

    tr td:nth-child(2) {
        width: 15%;
    }

    tr td:nth-child(5) {
        width: 18%;
    }

    tr td:nth-child(9) {
        width: 5%;
    }
}

.quest {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    &:hover {
        -webkit-line-clamp: initial;
    }
}