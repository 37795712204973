@import '../../styles/colors.scss';

.nav-sidebar {
    width: 80px;
    transition: width 0.3s ease-out;
}

.nav-sidebar-open {
    width: 320px;
    transition: width 0.3s ease-out;
    animation-fill-mode: forwards;
}

.nav-sidebar,
.nav-sidebar-open {
    flex: 1;
    background: $tertiary-700;
    display: flex;
    flex-direction: column;

    position: fixed;
    height: 100%;
    z-index: 12;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.65);

    button {
        margin-left: 1rem;
    }

    a {
        color: #F6F1EB;
    }

    button:hover {
        background: $tertiary-600;
    }
}

.header-sidebar {
    background: #702826;
    display: flex;
    height: 50px;
    margin-bottom: 20px;
}

.sidebar-a {
    display: flex;
    margin: auto 0;
    padding-left: 1rem;
    align-items: center;
}

.sidebar-logo {
    padding: 0;
    margin: 0;
    align-items: center;
    font-weight: bold;
    font-size: 26px;
    color: #fff;
}

.sidebar-ul {
    display: flex;
    flex-direction: column;
    background-position: center;
    margin-bottom: 20px;
}

.sidebar-li {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 44px;

    cursor: pointer;
    padding-left: 1rem;
    transition: all 0.7s;

    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        color: #F6F1EB;
        margin: 0 1rem 0 1rem;
        height: 44px;


        &:hover {
            color: #F6F1EB;
            background: transparent;
        }
    }

    span {
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
        margin: 0 1rem 0 .3rem;
    }
}

.sub-sidebar-ul {
    display: flex;
    flex-direction: column;
    background-position: center;
    transition: height .3s;
    height: 44px;
}

.sub-sidebar-ul-hidden {
    transition: height .5s;
    height: 0;
    overflow: hidden;
}

.sub-sidebar-li {
    display: flex;
    flex-direction: column;
    background-position: center;
    transition: background 0.8s;

    a {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        height: 44px;
        color: $primary-900;
        padding-left: 2rem;

        &:hover {
            color: $primary;
            background: transparent
        }

        svg {
            margin-right: .5rem;
        }
    }
}

.sidebar-icon {
    background: $tertiary-600;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}