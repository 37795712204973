.blur {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px)
}

.modal-backdrop.show {
    opacity: .8 !important;
}

.mp-modal-xsm .modal-content {
    max-height: 100% !important;
}

.contain {
    margin: 5% 8% 3% 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
}