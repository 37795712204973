// Sistema de grids

.grid-detalhe-content {
    display: grid;
    gap: 8px 8px;
    grid-template-columns: repeat(12, 1fr);

    [class*="col"] {
        padding: 14px;
    }

    .col-1-12 {
        grid-column: 1 / span 12;
    }

    .col-1-1 {
        grid-column: 1 / span 1;
    }

    .col-1-4 {
        grid-column: 1 / span 4;
    }

    .col-2-4 {
        grid-column: 5 / span 4;
    }

    .col-3-4 {
        grid-column: 9 / span 4;
    }

    .col-1-9 {
        grid-column: 1 / span 8;
    }

    .col-2-9 {
        grid-column: 9 / span 4;
    }

    .col-2-10 {
        grid-column: 2 / span 10;
    }

    .col-12-1 {
        grid-column: 12 / span 1;
    }

    .row-position-3 {
        grid-row: 3;
    }

    //paddings:
    .no-padding {
        padding: 0 !important;
    }

    .no-padding-top {
        padding-top: 0;
    }

    .no-padding-bottom {
        padding-bottom: 0;
    }

    .no-padding-left {
        padding-left: 0;
    }

    .no-padding-right {
        padding-right: 0;
    }
}

.grid-relatorio {
    display: grid;
    gap: 8px 8px;
    grid-template-columns: repeat(12, 1fr);

    [class*="col"] {
        padding: 16px 16px 14px;
    }

    .col-1-5 {
        grid-column: 1 / span 4;
    }

    .col-2-5 {
        grid-column: 5 / span 2;
    }

    .col-3-5 {
        grid-column: 7 / span 2;
    }

    .col-4-5 {
        grid-column: 9 / span 2;
    }

    .col-5-5 {
        grid-column: 11 / span 2;
    }
}