@import 'styles/colors.scss';

.box-tabs {
    border-bottom: 1px solid $primary-300;
}

.box-radio {
    margin-top: 20px;
}

.error-message-tipo-solicitacao {
    font-size: 12px;
    color: red;
    margin-top: 20px;
}