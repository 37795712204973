@import '../../styles/colors.scss';

.header-container {
  display: flex;
  justify-content: flex-end;
}


.header-box {
  display: flex;
  flex-direction: row;
  height: 44px;
  box-shadow: none;
  background: $white;
  width: 95%;

  a:hover {
    background: $white;
  }

  button:hover {
    background: $white;
  }
}

.bt-header-menu {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin: auto 0 auto 8px;

}

.header-logo-container {
  display: flex;
  margin: auto 0;
  align-items: center;
}

.header-logo-content {
  display: block;
  width: 231px;
  height: 32px;
  margin: 6px 8px;
}

.header-logo-a {
  display: flex;
  margin-left: 20px;

  .header-text {
    padding: 0;
    margin: 0;
    align-items: center;
    font-weight: bold;
    font-size: 26px;
    color: black;
  }
}