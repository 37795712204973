@import '../../styles/colors.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0 15px;
    width: 100%;
}

.avatar,
.avatar-large {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $secondary-600;

    p {
        font-weight: bolder;
        color: $secondary-200
    }
}

.avatar {
    width: 30px;
    height: 30px;
}

.avatar-large {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;

    p {
        font-size: 28px;
    }
}

.mp-contextual-menu .mp-contextual-menu-fixed-size-items-container {
    max-height: none !important;
}

.orgao {
    background-color: $secondary-600;
    border-radius: 20px;
    padding: 9px;
    display: flex;
    justify-content: center;
    margin-right: 30px;
}

.logout {
    margin-top: 15px;
    display: flex;
    align-items: center;
}

.container-menu {
    position: absolute;
    width: 320px;
    z-index: 3;
    top: 44px;
    right: 8px;
}

.box-menu {
    background: #fff;
    border: 2px solid transparent;
    border-radius: 4px;
    transition: .3s;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .14), 0 2px 1px rgba(0, 0, 0, .12), 0 1px 3px rgba(0, 0, 0, .2);
    width: 100%;
    height: 100%;
    animation: animeCard .3s ease;
    padding: 20px;
}

.menu-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .name {
        color: $secondary-600
    }

    .line {
        width: 100%;
        margin: 15px 0;
    }

    .orgao-title {
        font-size: 12px;
        text-align: center;
    }

    .mail {
        text-transform: lowercase;
        margin-bottom: 10px;
    }
}

.modal-orgao {
    padding: 20px;
}