.content-analise {
    box-shadow:
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 20px;
}

.group-radio {
    display: flex;
}

.error-message {
    font-size: 12px;
    color: red;
    margin-top: 10px;
}