.content {
  max-width: 1300px;
  margin-left: 80px;
}

.container-textarea {
  display: flex;
  flex-direction: column;
  margin: 0;

  label {
    padding: 0 16px 8px;
    display: flex;
    font-size: .88875em;
    font-family: 'Roboto' Medium;
  }

  textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 0;
    background: #fff;
    box-sizing: border-box;
    resize: none;
  }

  textarea:focus {
    outline: none;
  }
}

.button-align {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    margin: 0 16px 16px 0;
  }
}

.button-modal {
  margin-top: 20px;
}