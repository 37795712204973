.select-mp__option--is-selected,
.select-mp-error__option--is-selected {
    background-color: #b79266 !important;
}

.select-mp__value-container,
.select-mp__option {
    font-size: 14px !important;
}

.select-mp__option--is-focused,
.select-mp-error__option--is-focused {
    background-color: #f0e8df !important;
}

.select-mp__option--is-selected{
    color: #000000DE !important;
}

.select-mp__option,
.select-mp-error__option {
    &:hover {
        background-color: #f0e8df;
    }
}

.select-mp__control--is-focused {
    border-color: black !important;
    box-shadow: none !important;

    &:hover {
        border-color: none !important;
        box-shadow: none !important
    }
}

.select-mp-error {
    border: 1px solid red;
    border-radius: 5px;

}

.select-mp-error__placeholder {
    color: red !important;
}

.select-mp-error__control--is-focused {
    border-color: red !important;
    box-shadow: none !important;

    &:hover {
        border-color: none !important;
        box-shadow: none !important
    }
}

.select-mp__indicator,
.select-mp-error__indicator {
    svg {
        fill: #b79266
    }
}

.select-mp__multi-value {
    background-color: #b79266 !important;
}

.select-mp__multi-value__label {
    color: white !important;
}

.select-title {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    font-size: 14px;
    padding: 0 16px 4px;
}